import communication from "@/services/communication";
import {
  getFilterQueryStringWithoutArray,
  Role,
  getScope,
  CONSTANT as CONST
} from "@/helpers/helper.js";
import _ from "lodash";
import moment from "moment";
import { Device } from "@twilio/voice-sdk";

const getDefaultState = () => {
  return {
    getEmailConversation: [],
    getMessageConversation: [],
    getCandidateEmailMessages: [],
    getRecruiterEmailMessages: [],
    getNotificationMessages: [],
    getAlertMessages: [],
    getUnreadEmailMessages: 0,
    getEmailMessagesCount: 0,
    fullPathEmailBoxBackUrl: [],
    previousCount: 0,
    getEmailPagination: {
      limit: 15,
      skip: 0,
      noMoreDataFromEmailList: false,
    },
    getCandidateEmailPagination: {
      limit: 15,
      skip: 0,
      noMoreDataFromEmailList: false,
    },
    getUserEmailAddress: [],
    getCandidateEmailAddress: [],
    getWindowWidth: window.innerWidth,
    getDevice: null,
    getCall: null,
    getMute: false,
    getCallItems: false,
    voiceCallModal: true,
    isFetchingEmailConversation: false,
    storedCandidateDocIds: [],
    storedOrgDocIds: [],
    emailCc: {},
  };
};

const state = getDefaultState();

const getters = {
  sse: (state) => state.sse,
  getEmailConversation: (state) =>
    state.getEmailConversation && state.getEmailConversation
      ? state.getEmailConversation.map((val) => ({
        sender: val.sender,
        subject: val.subject,
        load_datetime: moment
          .utc(val.load_datetime)
          .local()
          .format("DD MMM YYYY hh:mm A"),
        email_id: val.email,
        direction: val.direction,
        message_id: val.message_id,
        cc: val?.email_cc,
        body: val.body,
        read: val.read,
        job_id: val.job_id,
        customer_user_id: val.customer_user_id,
        sender_name: val.sender_name,
        candidate_uid: val.candidate_uid,
        customer_uid: val.customer_uid,
        organisation_id: val.organisation_id,
        email_attachment: val.email_attachment,
      }))
      : [],
  messageCount: (state) => state.messageCount,
  getMessageConversation: (state) => state.getMessageConversation,
  noMoreDataFromEmailList: (state) =>
    state.getEmailPagination.noMoreDataFromEmailList,
  getEmailPaginationSkip: (state) => state.getEmailPagination.skip,
  getEmailPaginationLimit: (state) => state.getEmailPagination.limit,
  getCandidateEmailPaginationSkip: (state) =>
    state.getCandidateEmailPagination.skip,
  getCandidateEmailPaginationLimit: (state) =>
    state.getCandidateEmailPagination.limit,
  getNotificationMessages: (state) => state.getNotificationMessages,
  getAlertMessages: (state) => state.getAlertMessages,
  getEmailMessagesCount: (state) => state.getEmailMessagesCount,
  getUnreadEmailMessages: (state) => state.getUnreadEmailMessages,
  getCandidateEmailMessages: (state) =>
    state.getCandidateEmailMessages && state.getCandidateEmailMessages
      ? state.getCandidateEmailMessages.map((val) => ({
        sender: val.sender,
        subject: val.subject,
        load_datetime: moment
          .utc(val.load_datetime)
          .local()
          .format("DD MMM YYYY hh:mm A"),
        email_id: val.email,
        direction: val.direction,
        message_id: val.message_id,
        body: val.body,
        read: val.read,
        job_id: val.job_id,
        customer_user_id: val.customer_user_id,
        sender_name: val.sender_name,
        candidate_uid: val.candidate_uid,
        customer_uid: val.customer_uid,
        customer_name: val.customer_name,
        organisation_id: val.organisation_id,
        organisation_name: val.organisation_name,
        attachment: val.email_attachment,
      }))
      : [],
  getRecruiterEmailMessages: (state) =>
    state.getRecruiterEmailMessages
      ? state.getRecruiterEmailMessages.map((val) => ({
        sender: val.sender,
        subject: val.subject,
        load_datetime: moment
          .utc(val.load_datetime)
          .local()
          .format("DD MMM YYYY hh:mm A"),
        email_id: val.email,
        direction: val.direction,
        message_id: val.message_id,
        body: val.body,
        read: val.read,
        job_id: val.job_id,
        customer_user_id: val.customer_user_id,
        sender_name: val.sender_name,
        candidate_uid: val.candidate_uid,
        customer_uid: val.customer_uid,
        organisation_id: val.organisation_id,
        attachment: val.email_attachment,
        candidate_display_uid: val.candidate_display_uid,
        job_display_uid: val.job_display_uid,
        user: val?.user_detail
      }))
      : [],
  emailBoxBackUrl: (state, getters, rootState) => {
    let { getRoleScopes } = getters;
    let emailBoxBackUrl = {
      admin: "/dashboard",
      facility: "/recruiter-dashboard",
    };
    let backURL = state.fullPathEmailBoxBackUrl;
    if (!backURL.length) {
      if (getRoleScopes[0] === Role.systemAdmin) {
        return emailBoxBackUrl.admin;
      } else {
        return emailBoxBackUrl.facility;
      }
    } else {
      return backURL[backURL.length - 1];
    }
  },
  getUserEmailAddress: (state) => state.getUserEmailAddress,
  getCandidateEmailAddress: (state) => state.getCandidateEmailAddress,
  getWindowWidth: (state) => state.getWindowWidth,
  getDevice: (state) => state.getDevice,
  getCall: (state) => state.getCall,
  getMute: (state) => state.getMute,
  getCallItems: (state) => state.getCallItems,
  voiceCallModal: (state) => state.voiceCallModal,
  storedCandidateDocIds: (state) => state.storedCandidateDocIds,
  storedOrgDocIds: (state) => state.storedOrgDocIds,
  isFetchingEmailConversation: (state) => state.isFetchingEmailConversation,
  emailCc: (state) => state.emailCc,
};

const mutations = {
  ["SET_CANDIDATE_EMAIL_MESSAGES"](state, payload) {
    state.getCandidateEmailMessages = payload;
  },
  ["SET_EMAIL_CONVERSATION"](state, payload) {
    state.getEmailConversation = payload;
  },
  ["SET_MESSAGE_CONVERSATION"](state, payload) {
    state.getMessageConversation = payload;
  },
  ["SET_NOTIFICATION_MESSAGES"](state, payload) {
    state.getNotificationMessages = payload;
  },
  ["SET_ALERT_MESSAGES"](state, payload) {
    state.getAlertMessages = payload;
  },
  ["SET_EMAIL_MESSAGES_COUNT"](state, data) {
    state.getEmailMessagesCount = data;
  },
  ["SET_UNREAD_EMAIL_MESSAGES"](state, payload) {
    state.getUnreadEmailMessages = payload;
  },
  ["SET_RECRUITER_EMAIL_MESSAGES"](state, payload) {
    state.getRecruiterEmailMessages = payload;
  },
  ["SET_EMAIL_MESSAGE_BACK_URL"](state, url) {
    state.fullPathEmailBoxBackUrl = [...state.fullPathEmailBoxBackUrl, url];
  },
  ["RESET_EMAIL_MESSAGE_BACK_URL"](state) {
    state.fullPathEmailBoxBackUrl = [];
  },
  ["REMOVE_EMAIL_MESSAGE_BACK_URL"](state, url) {
    let _index = _.findLastIndex(
      state.fullPathEmailBoxBackUrl,
      (e) => e === url
    );
    if (_index > -1) state.fullPathEmailBoxBackUrl.splice(_index, 1);
  },
  ["SET_USER_EMAIL_ADDRESS"](state, payload) {
    state.getUserEmailAddress = payload;
  },
  ["SET_CANDIDATE_EMAIL_ADDRESS"](state, payload) {
    state.getCandidateEmailAddress = payload;
  },
  ["SET_WINDOW_WIDTH"](state, data) {
    state.getWindowWidth = window.innerWidth;
  },
  ["SET_VOICE_CALL_DEVICE"](state, payload) {
    state.getDevice = payload;
  },
  ["SET_VOICE_CALL"](state, payload) {
    state.getCall = payload;
  },
  ["SET_VOICE_CALL_MUTE"](state, payload) {
    state.getMute = payload;
  },
  ["SET_VOICE_CALL_ITEMS"](state, payload) {
    state.getCallItems = payload;
    state.voiceCallModal = payload;
  },
  ["SET_IS_FETCHING_EMAIL"](state, payload) {
    state.isFetchingEmailConversation = payload;
  },
  ["STORED_CANDIDATE_DOC_IDS"](state, payload) {
    state.storedCandidateDocIds = payload;
  },
  ["STORED_ORG_DOC_IDS"](state, payload) {
    state.storedOrgDocIds = payload;
  },
  ["SET_EMAIL_CC"](state, payload) {
    state.emailCc = payload;
  },
};

const actions = {
  // Email - start
  getEmailConversation: ({ commit, getters, dispatch }, { email, sender }) => {
    let order = "-message_id";
    commit("SET_IS_FETCHING_EMAIL", true);
    return communication
      .getEmailConversation(email, sender, order)
      .then((res) => {
        commit("SET_EMAIL_CONVERSATION", res.data);
        commit("SET_IS_FETCHING_EMAIL", false);
      })
      .catch((err) => {
        commit("SET_IS_FETCHING_EMAIL", false);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error While Fetching Conversation!",
        });
        return err;
      });
  },

  fetchCandidateEmailMessages: (
    { commit, getters, dispatch },
    { email, direction, read }
  ) => {
    const {
      getCandidateEmailPaginationSkip,
      getCandidateEmailPaginationLimit,
    } = getters;
    const { customer_uid, organisation_id } = getters.candidateProfile;
    const { getCustomerTypeIDFromAccessToken } = getters
    let skip = getCandidateEmailPaginationSkip;
    let limit = getCandidateEmailPaginationLimit;
    let order = "-message_id";
    let queryPayload = {};
    if (read != undefined)
      queryPayload = { ...queryPayload, read }
    const query = getFilterQueryStringWithoutArray(queryPayload)
    commit("SET_IS_FETCHING_EMAIL", true);
    return communication
      .getCandidateEmailMessages(
        email,
        skip,
        limit,
        direction,
        order,
        customer_uid,
        getCustomerTypeIDFromAccessToken != CONST.CUSTOMER_TYPE.recruiter_agency && organisation_id || null,
        query
      )
      .then((res) => {
        commit("SET_CANDIDATE_EMAIL_MESSAGES", res.data);
        commit("SET_IS_FETCHING_EMAIL", false);
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error While Fetching Conversation!",
        });
        return err;
      });
  },

  fetchEmailMessagesCount: (
    { commit, getters },
    { sender, email, direction, read }
  ) => {
    let query;
    let rolecheck = getScope();

    if (
      [Role.customerCandidate, Role.systemCandidate].includes(rolecheck) &&
      email &&
      direction
    ) {
      const { customer_uid, organisation_id } = getters.candidateProfile;
      query = `email_id=${email}&direction=${direction}&customer_uid=${customer_uid}`;
    } else {
      if (sender && direction && read)
        query = `sender=${sender}&direction=${direction}&read=${read}`;
      else if (sender && direction)
        query = `sender=${sender}&direction=${direction}`;
      else if (email && direction)
        query = `email_id=${email}&direction=${direction}`;
    }
    return communication
      .getEmailMessagesAndCount(query)
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_MESSAGES_COUNT", data);
      })
      .catch((err) => {
        console.log("Error Fetching Notification Count", err);
        return err;
      });
  },

  fetchSearchTermEmailMessagesCount: (
    { commit, getters },
    { read, direction, searchTerm, email }
  ) => {
    let queryPayload = {};
    if (read && direction)
      queryPayload = { ...queryPayload, read, direction };
    else if (direction)
      queryPayload = { ...queryPayload, direction };
    if (email)
      queryPayload = { ...queryPayload, email };
    const query = getFilterQueryStringWithoutArray(queryPayload);
    return communication
      .getSearchTermEmailMessagesCount(query, searchTerm)
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_MESSAGES_COUNT", data);
      })
      .catch((err) => {
        console.log("Error Fetching Notification Count", err);
        return err;
      });
  },

  fetchSearchTermEmailMessages: (
    { commit, getters },
    { read, direction, searchTerm, email }
  ) => {
    const { getEmailPaginationSkip, getEmailPaginationLimit } = getters;
    let skip = getEmailPaginationSkip;
    let limit = getEmailPaginationLimit;

    let queryPayload = {};
    if (read && direction)
      queryPayload = { ...queryPayload, read, direction };
    else if (direction)
      queryPayload = { ...queryPayload, direction };
    if (email)
      queryPayload = { ...queryPayload, email };
    const query = getFilterQueryStringWithoutArray(queryPayload);

    return communication
      .getSearchTermEmailMessages(query, skip, limit, searchTerm)
      .then((res) => {
        let { data } = res;
        commit("SET_RECRUITER_EMAIL_MESSAGES", data);
        if (email)
          commit("SET_CANDIDATE_EMAIL_MESSAGES", data);
      })
      .catch((err) => {
        console.log("Error Fetching Notification Count", err);
        return err;
      });
  },

  fetchRecruiterEmailMessages: (
    { commit, getters },
    { sender, read, direction }
  ) => {
    const { getEmailPaginationSkip, getEmailPaginationLimit } = getters;
    let skip = getEmailPaginationSkip;
    let limit = getEmailPaginationLimit;

    let query;
    if (read && direction) query = `read=${read}&direction=${direction}`;
    else if (direction) query = `direction=${direction}`;
    commit("SET_IS_FETCHING_EMAIL", true);
    return communication
      .getRecruiterEmailMessages(sender, query, skip, limit)
      .then((res) => {
        let { data } = res;
        commit("SET_RECRUITER_EMAIL_MESSAGES", data);
        commit("SET_IS_FETCHING_EMAIL", false);
      })
      .catch((err) => {
        console.log("Error fetching Email Messages", err);
        return err;
      });
  },

  updateReadMessage: ({ commit, dispatch, getters }, payload) => {
    const { message_id, read, email } = payload;
    return communication
      .updateReadMessage(message_id, read)
      .then((res) => {
        // dispatch("fetchUnreadEmailMessages");
        return res;
      })
      .catch((err) => {
        console.log("Error while Update!", err);
        return err;
      });
  },

  sendOutboundEmail: (
    { commit, getters, dispatch },
    {
      sender,
      sender_name,
      customer_user_id,
      email_to,
      subject,
      message,
      job_id,
      candidate_uid,
      files,
      email_cc,
    }
  ) => {
    const { org_detail } = getters.getUser;
    const { location } = getters.getOrgLocation;
    let rolecheck = getScope();

    let formData = new FormData();
    formData.append("sender", sender);
    formData.append("sender_name", sender_name);
    formData.append("customer_user_id", customer_user_id);
    formData.append("email_to", email_to);
    formData.append("subject", subject);
    formData.append("message_content", message);

    if (email_cc) formData.append("email_cc", email_cc);
    if (org_detail) formData.append("org_name", org_detail.org_name);
    if (location) formData.append("org_location", location);
    if (job_id) formData.append("job_id", job_id);
    if (candidate_uid) formData.append("candidate_uid", candidate_uid);

    if (
      [
        Role.customerAdmin,
        Role.customerRecruiter,
        Role.systemAdmin,
        Role.systemRecruiter,
      ].includes(rolecheck)
    ) {
      const { customer_id, organisation_id } = getters.getUserDetails;
      if (customer_id) formData.append("customer_uid", customer_id);
      if (organisation_id) formData.append("organisation_id", organisation_id);
    } else if (
      [Role.customerCandidate, Role.systemCandidate].includes(rolecheck)
    ) {
      const { customer_uid, organisation_id } = getters.candidateProfile;
      formData.append("customer_uid", customer_uid);
      if (organisation_id)
        formData.append("organisation_id", organisation_id);
    }

    if (files.length != 0) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append("file", file);
      }
    }
    return communication
      .sendOutboundEmail(formData)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email sent successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to send email",
        });
        return err?.response?.status;
      });
  },

  downloadAttachment: ({ commit, getters }, { attachment_id }) => {
    return communication
      .downloadAttachment(attachment_id)
      .then((res) => {
        let { request } = res;
        let data_url = request.responseURL;
        return data_url;
      })
      .catch((err) => {
        return err;
      });
  },

  deleteEmailConversation: ({ dispatch }, { deleteId }) => {
    return communication
      .deleteEmailConversation(deleteId)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email deleted successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to delete email",
        });
        console.log("Error delete conversation", err);
        return err;
      });
  },

  fetchAllCustomerContactUsersEmail: (
    { commit, getters },
    { contact, org_id }
  ) => {
    const {
      getCustomerId,
      getOrgID,
      getRole,
      isUKMainAgency,
      isJobSupplierFromAccessToken,
      isCandidateSupplierFromAccessToken,
      getUserDetails: { org_detail },
    } = getters;
    let query = "";
    let orgId =
      org_id ||
        (isJobSupplierFromAccessToken && org_id) ||
        (isCandidateSupplierFromAccessToken && org_id)
        ? org_id
        : getOrgID;
    if (contact || contact == false) query = `?contact=${contact}`;
    if (orgId) query = query.replace("?", "&");
    if (orgId) query = `?organisation_id=${orgId}` + query;
    return communication
      .getAllCustomerContactUserEmail(
        getCustomerId,
        orgId,
        getRole,
        Role,
        query
      )
      .then((res) => {
        let { data } = res;
        commit("SET_USER_EMAIL_ADDRESS", data.reverse());
      })
      .catch((err) => {
        console.log("Error Fetching User Email", err);
        return err;
      });
  },

  fetchAllUserEmailSearchTerm: (
    { commit, getters },
    { searchTerm, org_type_id, org_id }
  ) => {
    const {
      getCustomerId,
      getOrgID,
      isJobSupplierFromAccessToken,
      isUKMainAgency,
    } = getters;
    const { org_detail } = getters.getUserDetails;
    let skip = 0;
    let limit = 15;
    let orgId =
      getOrgID && org_detail.is_child && !isJobSupplierFromAccessToken
        ? `&organisation_id=${getOrgID}`
        : (isUKMainAgency && org_type_id == 13) || (!isUKMainAgency && org_id)
          ? `&organisation_id=${org_id}`
          : ``;
    let searchTerms = searchTerm ? `&searchTerm=${encodeURIComponent(searchTerm)}` : ``;
    return communication
      .getAllUserEmailSearchTerm(getCustomerId, orgId, skip, limit, searchTerms)
      .then((res) => {
        let { data } = res;
        commit("SET_CANDIDATE_EMAIL_ADDRESS", data);
      })
      .catch((err) => {
        console.log("Error Fetching User Email", err);
        return err;
      });
  },

  storeSelectedDocumentIds: (
    { commit, getters, state, dispatch },
    { name, type }
  ) => {
    const { storedCandidateDocIds, storedOrgDocIds } = state;
    if (type == "org") {
      commit("STORED_ORG_DOC_IDS", storedOrgDocIds.concat(name));
    } else {
      commit("STORED_CANDIDATE_DOC_IDS", storedCandidateDocIds.concat(name));
    }
  },

  removeSelectedDocumentIds: (
    { commit, getters, state, dispatch },
    { name, type }
  ) => {
    const { storedCandidateDocIds, storedOrgDocIds } = state;
    if (type == "org") {
      commit("STORED_ORG_DOC_IDS", _.without(storedOrgDocIds, name));
    } else {
      commit(
        "STORED_CANDIDATE_DOC_IDS",
        _.without(storedCandidateDocIds, name)
      );
    }
  },

  resetSelectedDocumentIds: ({ commit, getters, state, dispatch }) => {
    commit("STORED_ORG_DOC_IDS", []);
    commit("STORED_CANDIDATE_DOC_IDS", []);
  },

  fetchEmailCc: ({ commit, getters, state, dispatch }, payload) => {
    const { email, main_contact, customer_uid } = payload;
    let formData = new FormData();
    formData.append("email", email);
    if (main_contact) formData.append("main_contact", main_contact);
    if (customer_uid) formData.append("customer_uid", customer_uid);

    return communication
      .getEmailCc(formData)
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_CC", data);
        return res;
      })
      .catch((err) => {
        console.log("Error Fetching User Email", err);
      });
  },
  // End

  // whatsApp and Message - start
  getMessageConversation: (
    { commit, getters },
    { sender, receiver, media }
  ) => {
    return communication
      .getMessageConversation(sender, receiver, media)
      .then((res) => {
        commit("SET_MESSAGE_CONVERSATION", res.data);
      })
      .catch((err) => {
        console.log("Error fetching Message communication", err);
        return err;
      });
  },

  sendOutboundWhatsapp: (
    { commit, getters, dispatch },
    { sender, whatsapp_to, message }
  ) => {
    return communication
      .sendOutboundWhatsapp(sender, whatsapp_to, message)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Message sent successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to send message",
        });
        console.log("Error sending Message", err);
        return err?.response?.status;
      });
  },

  sendOutboundMessage: (
    { commit, getters, dispatch },
    { sender, message_to, message }
  ) => {
    return communication
      .sendOutboundMessage(sender, message_to, message)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Message sent successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to send message",
        });
        console.log("Error sending Message", err);
        return err?.response?.status;
      });
  },
  // End

  // Voice - start
  getVoiceCallDevice: ({ commit, getters, dispatch }) => {
    const { full_name } = getters.getUser;
    return communication
      .getVoiceCallDevice({ identity: full_name })
      .then((res) => {
        let { data } = res;

        let device = new Device(data.token, {
          codecPreferences: ["opus", "pcmu"],
          fakeLocalDTMF: true,
          enableRingingState: true,
        });
        commit("SET_VOICE_CALL_DEVICE", device);
        dispatch("addDeviceListeners", device);
      })
      .catch((err) => {
        console.log("Error fetching Message communication", err);
        return err;
      });
  },

  addDeviceListeners: ({ commit, getters, state }, device) => {
    device.on("registered", function () {
      console.log("Device Ready to make and receive calls!");
    });

    device.on("error", function (error) {
      console.log("Device Error: " + error.message);
    });

    device.audio.on("deviceChange", () => {
      console.log("device on change");
    });
  },

  makeVoiceCall: async ({ commit, getters, state, dispatch }, phone) => {
    const { getDevice } = getters;
    var params = {
      phone: phone,
    };

    if (getDevice) {
      const call = await getDevice.connect({ params });
      if (call) commit("SET_VOICE_CALL", call);

      call.on("accept", (call) => {
        commit("SET_VOICE_CALL_ITEMS", true);
      });

      call.on("disconnect", (call) => {
        commit("SET_VOICE_CALL_ITEMS", false);
      });

      call.on("mute", (isMuted, call) => {
        isMuted
          ? commit("SET_VOICE_CALL_MUTE", true)
          : commit("SET_VOICE_CALL_MUTE", false);
      });

      function updateUIDisconnectedOutgoingCall(params) {
        if (params == "Accept") {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: `${params}...`,
          });
        } else {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: `${params}...`,
          });
        }
      }
    } else {
      dispatch("showToast", {
        class: "bg-danger text-white",
        message: "Unable to make call.",
      });
    }
  },

  Hangup: ({ commit, getters, state }) => {
    const { getCall } = getters;
    getCall.disconnect();
  },

  muteCall: ({ commit, getters, state }, bool) => {
    const { getCall } = getters;
    getCall.mute(bool);
  },

  fetchDocumentFile({ state, commit, dispatch }, { url, document_name }) {
    return communication
      .fetchDocumentFile(url)
      .then((res) => {
        let { data } = res;
        var file = new File([data], document_name);
        return file;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while fetching documnets !",
        });
        console.error("error in Candidate Details", err);
        return err;
      });
  },
};

export default { state, getters, mutations, actions };
